import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'

import { Button, GlobalStyle, Inline, Input, Stack, Text } from '@procsea/design-system'

import { EMAIL_REGEX } from 'src/constants/constants'
import { useSessionStorageContext } from 'src/contexts/SessionStorageContext'
import { useAuth } from 'src/contexts/authContext'
import useDocumentTitle from 'src/hooks/useDocumentTitle'
import { useMarketplaceQuery } from 'src/queries/shared'
import { LocaleParams, LoginForm, LoginFormField } from 'src/types'

import { CardTitleContainer, InlineTextButton, SignupButtons } from './Login.styles'
import { AuthContainer, AuthMainCard, FullwidthCard, StyledRouterLink } from './common'
import AuthHeader from './common/AuthHeader'

declare global {
  interface Window {
    WEBSITE_URL?: string
  }
}

enum LoginError {
  CREDENTIALS = 'credentials',
  INTERNAL = 'internal',
  NONE = 'none',
}

const Login = () => {
  const auth = useAuth()
  const { control, errors, handleSubmit } = useForm<LoginForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [loginError, setLoginError] = useState<LoginError>(LoginError.NONE)
  const [signUpCardOpen, setSignUpCardOpen] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const { search } = useLocation()
  const { data: marketplace } = useMarketplaceQuery()
  const { setCurrentMembershipId } = useSessionStorageContext()

  useDocumentTitle(gettext('Sign in'))

  const { locale } = useParams<LocaleParams>()
  const passwordResetUrl = `/${locale}/accounts/password_reset`
  const buyerSignupUrl = `/${locale}/accounts/buyer`
  const sellerSignupUrl = `/${locale}/accounts/seller`

  const getButtonText = () => {
    if (loginError === LoginError.INTERNAL)
      return gettext('Something went wrong. Please try again.')
    if (loginError === LoginError.CREDENTIALS) return gettext('Invalid email and/or password')
    if (isLoading) return gettext('Loading...')
    if (isLogged) return gettext('Logged successfully!')
    return gettext('Sign in')
  }

  const getButtonVariant = () => {
    if (loginError !== LoginError.NONE) return 'danger'
    if (isLogged) return 'success'
    return 'primary'
  }

  const onSubmit = async (form: LoginForm) => {
    setCurrentMembershipId(null)
    setIsLoading(true)
    const { status } = await auth.login({
      email: form[LoginFormField.EMAIL],
      password: form[LoginFormField.PASSWORD],
    })
    if (status === 200) {
      setIsLogged(true)
      setLoginError(LoginError.NONE)
    } else {
      setIsLogged(false)
      setLoginError(status === 400 ? LoginError.CREDENTIALS : LoginError.INTERNAL)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (isLogged) {
      const nextUrl = search.split('?next=')[1]
      window.location.href = nextUrl || '/'
    }
  }, [isLogged])

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />

      <AuthHeader data-e2e="login-home-link" />

      <AuthMainCard shadow="middle" spacing="xlarge">
        <CardTitleContainer>
          <Text size="xxxlarge" variant="secondary">
            {gettext('Sign in')}
          </Text>
        </CardTitleContainer>

        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="large">
            <Controller
              control={control}
              defaultValue=""
              name={LoginFormField.EMAIL}
              render={({ onBlur, onChange, value: controllerValue }) => (
                <Input
                  fluid
                  data-e2e="login-email-input"
                  disabled={isLoading || isLogged}
                  errorMessage={errors[LoginFormField.EMAIL]?.message}
                  hasError={!!errors[LoginFormField.EMAIL]}
                  label={gettext('Email')}
                  name={LoginFormField.EMAIL}
                  onBlur={onBlur}
                  onChange={({ value }: { value: string }) => {
                    onChange(value)
                    setLoginError(LoginError.NONE)
                  }}
                  type="email"
                  value={controllerValue}
                />
              )}
              rules={{
                pattern: {
                  message: gettext('Invalid email'),
                  value: EMAIL_REGEX,
                },
                required: gettext('This field is required'),
              }}
            />

            <Controller
              control={control}
              defaultValue=""
              name={LoginFormField.PASSWORD}
              render={({ onBlur, onChange, value: controllerValue }) => (
                <Input
                  fluid
                  data-e2e="login-password-input"
                  disabled={isLoading || isLogged}
                  errorMessage={errors[LoginFormField.PASSWORD]?.message}
                  hasError={!!errors[LoginFormField.PASSWORD]}
                  icon={isPasswordVisible ? 'eye' : 'eye-slash'}
                  label={gettext('Password')}
                  name={LoginFormField.PASSWORD}
                  onBlur={onBlur}
                  onChange={({ value }: { value: string }) => {
                    onChange(value)
                    setLoginError(LoginError.NONE)
                  }}
                  onIconClick={() => setIsPasswordVisible(oldValue => !oldValue)}
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={controllerValue}
                />
              )}
              rules={{
                required: gettext('This field is required'),
              }}
            />

            <Button
              data-e2e="login-submit-button"
              disabled={isLogged}
              type="submit"
              variant={getButtonVariant()}
            >
              {getButtonText()}
            </Button>

            <StyledRouterLink data-e2e="login-forgot-password-button" to={passwordResetUrl}>
              {gettext('Forgot password?')}
            </StyledRouterLink>
          </Stack>
        </form>
      </AuthMainCard>

      {signUpCardOpen ? (
        <FullwidthCard shadow="middle" spacing="xlarge">
          <Stack spacing="large">
            <Text size="large" variant="neutral">
              {gettext('Choose your type of account:')}
            </Text>

            <SignupButtons>
              <RouterLink data-e2e="login-buyer-signup-button" to={buyerSignupUrl}>
                <Button fluid outline variant="primary">
                  {gettext('Customer sign up')}
                </Button>
              </RouterLink>

              <RouterLink data-e2e="login-seller-signup-button" to={sellerSignupUrl}>
                <Button fluid outline variant="primary">
                  {gettext('Supplier sign up')}
                </Button>
              </RouterLink>
            </SignupButtons>
          </Stack>
        </FullwidthCard>
      ) : (
        <div>
          <Inline spacing="xsmall">
            <Text size="medium" variant="neutral">
              {gettext("Don't have an account?")}
            </Text>

            {!marketplace?.length ? (
              <InlineTextButton
                data-e2e="login-signup-open-button"
                onClick={() => setSignUpCardOpen(true)}
              >
                {gettext('Sign up here')}
              </InlineTextButton>
            ) : (
              <RouterLink data-e2e="login-customer-signup-button" to={buyerSignupUrl}>
                <InlineTextButton>{gettext('Sign up here')}</InlineTextButton>
              </RouterLink>
            )}
          </Inline>
        </div>
      )}
    </AuthContainer>
  )
}

export default Login
